import { Component, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AreaAtuacaoService } from 'app/modulos/area-atuacao/area-atuacao.service';
import { AreaAtuacaoDTO } from 'app/shared/model/area-atuacao-dto';
import { DialogData } from '../dialog/dialog-data';
import { SelectItem } from '../dropdown/select-item';

@Component({
  selector: 'app-analise-area-atuacao-modal',
  templateUrl: './analise-area-atuacao-modal.component.html',
  styleUrls: ['./analise-area-atuacao-modal.component.scss']
})
export class AnaliseAreaAtuacaoModalComponent implements OnInit {
  form: UntypedFormGroup;
  areaAtuacao: SelectItem[] = [];

	constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
		readonly formBuilder: UntypedFormBuilder,
		readonly areaAtuacaoService: AreaAtuacaoService,
		public modalRef: MatDialogRef<AnaliseAreaAtuacaoModalComponent>
	) {
	
	}

	ngOnInit(): void {
    this.buscarAreaAtuacao();
    
    this.form = this.formBuilder.group({
      areaAtuacao: [null, Validators.required],
    });
	}

  onOkClick(){
    this.modalRef.close(this.form.get('areaAtuacao').value);
  }

  onNoClick(): void {
    this.modalRef.close(null);
  }

  buscarAreaAtuacao(){
    this.areaAtuacaoService.buscarAreasAtuacao().subscribe(r=>{
      r.forEach(areaAtuacao=>{
        this.areaAtuacao.push(
          {label: areaAtuacao.nome, value: areaAtuacao});
      });
    })
  }
}