<div [formGroup]="form">
	<div *ngIf="disabled; then other_content; else content"></div>
	<ng-template #content>
		<label *ngIf="showLabel == true">
			{{ label }}
			<span *ngIf="required()" class="required-text">(Obrigatório)</span>
		</label>
		<mat-form-field class="form-field" appearance="outline">
			<input matInput [formControlName]="controlName" [value]="value" [maxlength]="maxlength" [required]="required()" [readonly]="readonly" [placeholder]="placeholder"/>
			<mat-error *ngIf="form.get(controlName).touched && form.get(controlName).invalid">
				<span class="feedback danger" role="alert">
					<i class="fas fa-times-circle" aria-hidden="true"></i>
					Preenchimento obrigatório
				</span>
			</mat-error>
		</mat-form-field>
	</ng-template>

	<ng-template #other_content>
		<label>{{ label }}</label>
		<mat-form-field class="form-field" appearance="outline">
			<input matInput disabled [value]="value" />
		</mat-form-field>
	</ng-template>
</div>
