<label *ngIf="label">
	{{ label }}
	<span *ngIf="required()" class="required-text">(Obrigatório)</span>
</label>
<div class="dropdown">
	<mat-form-field class="form-field" appearance="outline">
		<mat-select [formControl]="form.get(controlName)" [disabled]="disabled" [required]="required()" [placeholder]="placeholder">
			<mat-option>Selecione</mat-option>
			<mat-option *ngFor="let item of items" [value]="item.value">
				{{ item.label }}
			</mat-option>
		</mat-select>

		<mat-error *ngIf="form.get(controlName).touched && form.get(controlName).invalid">
			<span class="feedback danger" role="alert">
				<i class="fas fa-times-circle" aria-hidden="true"></i>
				Preenchimento obrigatório
			</span>
		</mat-error>
	</mat-form-field>
</div>
