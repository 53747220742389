import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { DomSanitizer } from '@angular/platform-browser';

import {DialogData} from "./simple-dialog-data";

@Component({
    selector: 'simple-dialog',
    templateUrl: 'simple-dialog.component.html',
    styleUrls: ['./simple-dialog.component.scss'],
})
export class SimpleDialogComponent {

    constructor(public dialogRef: MatDialogRef<SimpleDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private sanitizer: DomSanitizer) {}

    @Output()
    result = new EventEmitter();

    dialogResult: any;

    onNoClick(): void {
        this.dialogRef.close();
    }

    safeUrl(url:string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    
}
