import { SelectionModel } from "@angular/cdk/collections";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";

import { Page, PageRequest } from "../../../shared/util/pagination";
import { SimpleDialogComponent } from "../simple-dialog/simple-dialog.component";
import { TabelaHeader, PayloadTabela, TabelaCustomButton, TabelaColunaIcone } from '../../../shared/model/tabela-model';

@Component({
	selector: "super-tabela",
	templateUrl: "./super-tabela.component.html",
	styleUrls: ["./super-tabela.component.scss"],
})
export class SuperTabelaComponent implements OnChanges {

	@Input()
	totalItens: number;

	@Input()
	paginaAtual: number;

	paginas: number[];

	paginacaoReticenciasLimite: number = 2;

	@Input()
		get totalPaginas(): number[] { return this._totalPaginas };
		set totalPaginas(tp: number[]){
			this.paginas = []
			if(tp !== undefined && tp !== null && tp.length > 0){
				this._totalPaginas = tp;
				this.paginaInicial = Math.min(...this._totalPaginas);
				this.paginaFinal = Math.max(...this._totalPaginas);

				//pagina inicial 
				if(this.paginaAtual == this.paginaFinal){
					this.paginas.pop()
				} else{
					this.paginas.push(this.paginaInicial);
				}

				//definição da primeira reticencias
				if(this.paginaAtual - this.paginacaoReticenciasLimite > 2){
					this.paginas.push(-1);
				}

				//definição das 3 paginas antes da pagina atual
				for (let i = this.paginaAtual - this.paginacaoReticenciasLimite; i < this.paginaAtual; i++){
					if(i > 1){
						this.paginas.push(i);
					}
				}

				//definição da pagina atual
				if(this.paginaAtual != this.paginaInicial && this.paginaAtual != this.paginaFinal){
					this.paginas.push(this.paginaAtual);
				}

				//definição das 3 paginas após a pagina atual
				for(let i = this.paginaAtual + 1; i < this.paginaFinal && i <= this.paginaAtual + this.paginacaoReticenciasLimite; i++){
					this.paginas.push(i);
				}

				//definição da segunda reticencias
				if(this.paginaAtual + this.paginacaoReticenciasLimite < this.paginaFinal - 1){
					this.paginas.push(-2);
				}

				//definição da ultima pagina
				this.paginas.push(this.paginaFinal);
			}
		}

	@Input()
	primeiraPagina?: boolean;

	@Input()
	ultimaPagina?: boolean;
	
	@Input()
	title = "";

	@Input()
	header: Array<TabelaHeader> = [];

	@Input()
	rows: Array<any> = [];

	@Output()
	paginaSelecionada: EventEmitter<number> = new EventEmitter();

	@Output()
	editar = new EventEmitter<PayloadTabela>();

	@Output()
	deletar = new EventEmitter<PayloadTabela>();

	@Output()
	visualizar = new EventEmitter<PayloadTabela>();

	@Output()
	download = new EventEmitter<PayloadTabela>();

	@Output()
	customBtn = new EventEmitter<PayloadTabela>();

	displayedColumns: string[];
	dataSource: MatTableDataSource<any>;
	selection = new SelectionModel<any>(true, []);
	
	paginaInicial: number;
	paginaFinal: number;
	private	_totalPaginas: number[];

	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatTable) table: MatTable<any>;

	constructor(private dialog: MatDialog) {}

	ngOnChanges(changes: SimpleChanges) {
		if (typeof this.header != "undefined") {
			this.displayedColumns = this.header.map(function (e) {
				return e.field;
			});
		}
	}

	renderContent(index: number, row: any, field: string, column: TabelaHeader) {
		if (typeof column.render == "function") {
			return column.render(index, row, field);
		} else {
			return row[field];
		}
	}

	renderizarIcone(index: number, row: any, field: string, column: TabelaHeader): boolean {
		if (typeof column.icon !== "undefined") {
			return column.icon.exibir(index, row, field);
		}

		return false;
	}

	showActionButton(button: string, column: any): boolean {
		return column.hasOwnProperty("actions") && column.actions.includes(button);
	}

	showCustomActionButton(btn: any): boolean {
		return typeof btn === "object" && !Array.isArray(btn) && btn !== null;
	}

	emitPreview(index: number, row: any, field: string, column: TabelaHeader) {
		this.visualizar.emit({
			index: index,
			entity: row,
			field: field,
			column: column,
		} as PayloadTabela);
	}

	emitEdit(index: number, row: any, field: string, column: TabelaHeader) {
		this.editar.emit({
			index: index,
			entity: row,
			field: field,
			column: column,
		} as PayloadTabela);
	}

	emitDownload(index: number, row: any, field: string, column: TabelaHeader) {
		this.download.emit({
			index: index,
			entity: row,
			field: field,
			column: column,
		} as PayloadTabela);
	}

	askDelete(index: number, row: any, field: string, column: TabelaHeader): void {
		const dialogRef = this.dialog.open(SimpleDialogComponent, {
			data: {
				title: "Deseja apagar o registro?",
				cancelButton: {
					show: true,
					label: "Não",
				},
				okButton: {
					show: true,
					label: "Sim",
				},
			},
		});

		dialogRef.afterClosed().subscribe(result => {
			this.deletar.emit({
				confirm: result,
				index: index,
				entity: row,
				field: field,
				column: column,
			} as PayloadTabela);
		});
	}

	emitCustomBtn(index: number, row: any, btn: TabelaCustomButton) {
		this.customBtn.emit({
			index: index,
			entity: row,
			btn: btn,
		});
	}

	sortTable(event: any): void {
		//- ASC -> Crescente
		//- DESC -> Decrescente
		const coluna = event.active;

		if (event.direction) {
			if (event.direction === "asc") {
				this.rows.sort((a: any, b: any) => {
					if (a[coluna] > b[coluna]) return -1;
					if (a[coluna] < b[coluna]) return 1;
					return 0;
				});

				this.rows = JSON.parse(JSON.stringify(this.rows));
			} else if (event.direction === "desc") {
				this.rows.sort((a: any, b: any) => {
					if (a[coluna] < b[coluna]) return -1;
					if (a[coluna] > b[coluna]) return 1;
					return 0;
				});

				this.rows = JSON.parse(JSON.stringify(this.rows));
			}
		}
	}

	selecionaPagina(pagina: number) {
		this.paginaSelecionada.emit(pagina);
	}

}
