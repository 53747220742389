import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { DialogData } from "./textarea-dialog-data";

@Component({
    selector: 'textarea-dialog',
    templateUrl: 'textarea-dialog.component.html',
    styleUrls: ['./textarea-dialog.component.scss'],
})
export class TextareaDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<TextareaDialogComponent>, 
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        readonly formBuilder: UntypedFormBuilder,
    ) {
        // this.dialogRef.beforeClosed().subscribe(() => {
        //     this.dialogRef.close(this.form.get('conteudoTA').value);
        // })

        this.dialogRef.afterOpened().subscribe(() => {
            this.form.get('conteudoTA').setValue(data.content)
        })
    }
    form: UntypedFormGroup;

    @Output()
    result = new EventEmitter();

    dialogResult: any;

    onOkClick(){
        this.dialogRef.close(this.form.get('conteudoTA').value);
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    ngOnInit() {

        this.form = this.formBuilder.group({
            conteudoTA: [null, null],
        })

    }



}
