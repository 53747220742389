<div class="mb-2">
	<h5 *ngIf="title">{{ title }}</h5>

	<table mat-table [dataSource]="rows" matSort (matSortChange)="sortTable($event)">
		<ng-container *ngFor="let column of header" [matColumnDef]="column.field">
			<th mat-header-cell *matHeaderCellDef mat-sort-header >
				{{ column.title }}
			</th>
			<ng-template *ngIf="column?.actions; then actions; else content"></ng-template>
			<ng-template #content>
				<td mat-cell *matCellDef="let row; let i = index" [title]="column.showContentTitle === true ? row[column.field] : ''">
					<i [ngClass]="cssClasses" class="material-icons" *ngIf="renderizarIcone(i, row, column.field, column)" [title]="column.icon.title">{{ column.icon.icon }}</i>
					{{ renderContent(i, row, column.field, column) }}
				</td>
			</ng-template>
			<ng-template #actions>
				<td mat-cell *matCellDef="let row; let i = index">
					<button
						*ngIf="showActionButton('preview', column)"
						(click)="emitPreview(i, row, column.field, column)"
						type="button"
						matTooltip="Visualizar"
						matTooltipClass="toolTip"
						class="br-button circle small"
					>
						<i class="material-icons">visibility</i>
					</button>
					<button
						*ngIf="showActionButton('edit', column)"
						(click)="emitEdit(i, row, column.field, column)"
						type="button"
						matTooltip="Editar"
						matTooltipClass="toolTip"
						class="br-button circle small"
					>
						<i class="material-icons">edit</i>
					</button>
					<button
						*ngIf="showActionButton('delete', column)"
						(click)="askDelete(i, row, column.field, column)"
						type="button"
						matTooltip="Deletar"
						matTooltipClass="toolTip"
						class="br-button circle small"
					>
						<i class="material-icons">delete</i>
					</button>
					<button
						*ngIf="showActionButton('download', column)"
						(click)="emitDownload(i, row, column.field, column)"
						type="button"
						matTooltip="Download"
						matTooltipClass="toolTip"
						class="br-button circle small"
					>
						<i class="material-icons">download</i>
					</button>
					<ng-container *ngFor="let btn of column.actions">
						<button
							*ngIf="showCustomActionButton(btn, column)"
							(click)="emitCustomBtn(i, row, btn)"
							type="button"
							matTooltip="{{ btn?.title }}"
							matTooltipClass="toolTip"
							class="br-button circle small"
						>
							<i class="material-icons">{{ btn.icon }}</i>
							{{ btn.label }}
						</button>
					</ng-container>
				</td>
			</ng-template>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

	</table>
	<nav class="br-pagination d-lg-flex justify-content-lg-between" aria-label="Paginação de resultados" *ngIf="paginas != null && paginas.length > 0">
		<div></div>
		<ul>
			<li *ngIf="!primeiraPagina">
				<button class="br-button circle text-center" type="button" data-previous-page="data-previous-page" aria-label="Página anterior" (click)="selecionaPagina(paginaAtual - 1)">
					<i class="material-icons">arrow_back_ios</i>
				</button>
			</li>
		
			<li *ngFor="let pagina of paginas">

				<div *ngIf="pagina < paginaAtual && pagina != -2">
					<a *ngIf="pagina != -1; else elsePrimeiraReticencias" class="page" (click)="selecionaPagina(pagina)">{{ pagina }}</a>
					<ng-template #elsePrimeiraReticencias>
						<button class="br-button circle" type="button" aria-label="Abrir listagem"><i class="fas fa-ellipsis-h" aria-hidden="true"></i></button>
					</ng-template>
				</div>

				<div *ngIf="pagina === paginaAtual">
					<a class="page active" (click)="selecionaPagina(pagina)">{{ pagina }}</a>
				</div>

				<div *ngIf="(pagina > paginaAtual && pagina != -1) || (pagina == -2)">
					<a *ngIf="pagina != -2; else elseSegundaReticencias" class="page" (click)="selecionaPagina(pagina)">{{ pagina }}</a>
					<ng-template #elseSegundaReticencias>
						<button class="br-button circle" type="button" aria-label="Abrir listagem"><i class="fas fa-ellipsis-h" aria-hidden="true"></i></button>
					</ng-template>				
				</div>
			</li>

			<li *ngIf="!ultimaPagina">
				<button class="br-button circle text-center" type="button" data-next-page="data-next-page" aria-label="Página seguinte" (click)="selecionaPagina(paginaAtual + 1)">
					<i class="material-icons">arrow_forward_ios</i>
				</button>
			</li>
		</ul>
	
		<div class="pagination-information d-none d-sm-flex" *ngIf="totalItens != null">
			<span class="total">{{ totalItens }}</span>
			&nbsp;itens
		</div>
	</nav>	
</div>

