import { Component, Input, OnInit, EventEmitter, Output, DoCheck, ViewChild, ElementRef } from "@angular/core";
import { DsInputUploadService } from "./ds-input-upload.service";
import { HttpEventType } from "@angular/common/http";
import { DocumentoSolicitacao } from "app/shared/model/documento-solicitacao";
import { NotificationService } from "app/shared/service/notification.service";
import { UntypedFormGroup } from "@angular/forms";

@Component({
	selector: "app-ds-input-upload",
	templateUrl: "./ds-input-upload.component.html",
	styleUrls: ["./ds-input-upload.component.scss"],
})
export class DsInputUploadComponent implements OnInit, DoCheck {
	constructor(private readonly dsInputUploadService: DsInputUploadService, private notificationService: NotificationService) {}

	ngOnInit(): void {}

	ngOnChanges(): void {
		if (this.listaDocumentosSolicitacao) this.listaDocumentosSolicitacao.forEach(element => this.populaAquivosUnico(element));
	}

	ngDoCheck() {
		if (this.obrigatorio) {
			this.validaArquivo();
		}
	}

	@ViewChild("inputFile") inputFile: ElementRef;

	@Input() label: string | null;

	@Input() orientacao: string | null;

	@Input() orientacaoComplementacao: string | null;

	@Input() id: string | null;

	@Input() nameControlReativo: string | null;

	@Input() form: UntypedFormGroup;

	@Input() multiplosAquivos: boolean;

	@Input() listaErros: string[] | null;

	@Input() urlApi: string;

	@Input() urlApiDelete: string;

	@Input() tipoArquivo: string | null;

	@Input() solicitacaoProtocolo: string | null;

	@Input() tipoDocumentoId: number | null;

	@Input() listaDocumentosSolicitacao: DocumentoSolicitacao[] | null;

	@Input() obrigatorio: boolean;

	@Input() tamanhoMaximo: number = 50;

	@Input() possuiAlerta: boolean = false;

	@Input() destaqueLabel: string = null;

	@Input() disabled: boolean = false;

	@Output() temArquivo = new EventEmitter<boolean>();

	@Output() documentoSalvo: EventEmitter<any> = new EventEmitter<any>();

	erroTamanho: string;

	possuiErro: boolean = false;

	arquivoParaDownload: boolean = false;

	arquivoUnicoID: number;

	arquivoCarregando: Boolean = false;

	arquivoSobrescrito: Boolean = false;

	listaArquivosUpload: any[] = [];

	listaArquivosVisualizacao: any[] = [];

	aoSelecionarArquivos($event: Event) {
		const arquivosRecebidos = [...$event.target["files"]];

		if (!this.multiplosAquivos) {
			this.listaArquivosVisualizacao = [];
			if (this.verificaExtensaoArquivo(arquivosRecebidos[0])) {
				if (this.verificaTamanhoAquivo(arquivosRecebidos[0])) {
					this.upload(arquivosRecebidos[0]);
					if (this.listaErros) {
						this.listaErros = this.listaErros.filter(element => {
							element != this.erroTamanho;
						});
					}
					this.possuiErro = false;
				}
			}
		}

		let arquivosUpload = arquivosRecebidos;

		if (arquivosUpload && arquivosUpload.length > 0 && this.multiplosAquivos) {
			arquivosUpload.forEach(arquivo => {
				if (this.verificaExtensaoArquivo(arquivo)) {
					if (this.verificaTamanhoAquivo(arquivo)) {
						this.upload(arquivo);
						this.possuiErro = false;
						if (this.listaErros) {
							this.listaErros = this.listaErros.filter(element => {
								element != this.erroTamanho;
							});
						}
					} else {
						arquivosUpload = arquivosUpload.filter(element => element.name !== arquivo.name);
						this.listaArquivosVisualizacao = arquivosUpload;
					}
				}
			});
		}
	}

	onClickDelete(arquivo: any) {
		if (this.listaDocumentosSolicitacao) {
			this.listaDocumentosSolicitacao.forEach(element => {
				if (element.nome === arquivo.name || element.nome === arquivo.nome) {
					this.delete(element.id, arquivo);
				}
			});
		}

		if (this.listaArquivosUpload) {
			this.listaArquivosUpload.forEach(element => {
				if (element.nome === arquivo.name) {
					this.delete(element.id, arquivo);
				}
			});
		}
	}

	upload(arquivo: File) {
		const file = arquivo;
		const reader = new FileReader();
		reader.readAsDataURL(file);

		let documentoId = null;

		if (this.arquivoUnicoID && !this.multiplosAquivos) {
			documentoId = this.arquivoUnicoID;
			this.arquivoSobrescrito = true;
			this.notificationService.alerta("Arquivo substituído");
		}

		reader.onload = () => {
			let documento: DocumentoSolicitacao = {
				id: documentoId,
				file: reader.result as string,
				tipoDocumentoId: this.tipoDocumentoId,
				nome: file.name,
				protocolo: this.solicitacaoProtocolo,
			};

			this.dsInputUploadService.upload(documento, this.urlApi).subscribe({
				next: (response: any) => {
					this.arquivoUnicoID = response.id;
					const arquivo = {
						id: response.id,
						nome: response.nome,
						tipoDocumentoId: this.tipoDocumentoId,
						identificador: response.identificador,
					};
					this.listaArquivosUpload.push(arquivo);
					this.documentoSalvo.emit(arquivo);
					this.listaArquivosVisualizacao.push(file);
					if (response.type === HttpEventType.UploadProgress) {
						this.arquivoCarregando = true;
					}
				},
				error: (error: any) => {
					this.notificationService.erro(error.message);
				},
				complete: () => {
					this.arquivoCarregando = false;
				},
			});
		};
	}

	onClickDownload(arquivo: any, index: number) {
		this.dsInputUploadService.download(this.listaDocumentosSolicitacao[index]).subscribe((response: Blob) => {
			console.log('response',response)
			this.dsInputUploadService.handleDownload(response, `${arquivo.name || arquivo.nome} .pdf`);
		});
	}

	verificaSeTemArquivo(): boolean {
		return this.listaArquivosVisualizacao.length > 0 ? true : false;
	}

	populaArquivos(documento: DocumentoSolicitacao) {
		if (documento) {
			this.listaArquivosVisualizacao.push(documento);
			this.listaDocumentosSolicitacao = [];
			this.listaDocumentosSolicitacao.push(documento);
			this.arquivoParaDownload = true;
		}
	}

	populaAquivosUnico(documento: DocumentoSolicitacao) {
		if (documento) {
			this.listaDocumentosSolicitacao.push(documento);
			this.arquivoParaDownload = true;
		}
	}
	delete(id: number, arquivo: any) {
		this.dsInputUploadService.delete(id, this.urlApiDelete).subscribe({
			error: (error: any) => {
				this.notificationService.erro("Ocorreu um erro ao deletar o arquivo");
			},
			complete: () => {
				this.notificationService.limpar();
				this.notificationService.sucesso(`${arquivo.nome || arquivo.name} removido com sucesso`);
				this.listaArquivosVisualizacao = this.listaArquivosVisualizacao.filter(element => {
					return element !== arquivo;
				});
				this.inputFile.nativeElement.value = "";
			},
		});
	}

	validaArquivo() {
		this.temArquivo.emit(this.verificaSeTemArquivo());
	}

	verificaTamanhoAquivo(file: File): boolean {
		let tamanhoBytes = file.size;
		const tamanhoArquivo = Math.floor(tamanhoBytes / 1048576);

		if (tamanhoArquivo > this.tamanhoMaximo) {
			this.erroTamanho = `${file.name} tamanho inválido. O arquivo não pode ter mais de ${this.tamanhoMaximo}MB`;
			this.possuiErro = true;
			this.listaErros = [];
			this.notificationService.erro(this.erroTamanho);
			this.listaErros.push(this.erroTamanho);
			return false;
		} else {
			return true;
		}
	}

	verificaExtensaoArquivo(file: File): boolean {
		if (file.type === "application/pdf") return true;
		this.notificationService.erro("Tipo de arquivo inválido, apenas arquivos do tipo PDF podem ser inseridos");
	}

	limpaCamposDeAquivos() {
		this.listaArquivosVisualizacao = [];
		this.listaArquivosUpload = [];
		this.arquivoUnicoID = null;
		this.arquivoSobrescrito = false;
		this.possuiErro = false;
		this.listaErros = [];
	}
}
