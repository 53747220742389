import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

import { DialogData } from "./dialog-data";

/**
 * Componente de Dialog
 */
@Component({
	selector: "app-dialog",
	templateUrl: "./dialog.component.html",
	styleUrls: ["./dialog.component.scss"],
})
export class DialogComponent implements OnInit {
	@Input()
	modalButtonName?: string;

	@Input()
	modalButtonIcon?: string;

	@Input()
	modalButtonType: "success" | "info" | "default" = "default";

	@Input()
	content: DialogData;

	@Input()
	opened: boolean = false;

	@Input()
	disabled: boolean = false;

	@Input()
	contentPreview: any;

	@Output()
	result = new EventEmitter();

	dialogResult: any;

	constructor(public dialog: MatDialog) {}

	ngOnInit(): void {
		if (this.opened) {
			this.openDialog();
		}
	}

	openDialog() {
		if (typeof this.contentPreview != "undefined") {
			this.content.content = [{ paragraphs: this.contentPreview }];
		}

		const dialogRef = this.dialog.open(DialogDataDialog, {
			data: {
				title: this.content.title,
				content: this.content.content ? this.content.content : null,
				cancelButton: this.content.cancelButton,
				okButton: this.content.okButton,
				dialogComponent: this.content.dialogComponent ? this.content.dialogComponent : null,
			},
			disableClose: this.content.config ? this.content.config.disableClose : null,
			maxHeight: this.content.config ? this.content.config.maxHeight : null,
			minHeight: this.content.config ? this.content.config.minHeight : null,
			minWidth: this.content.config ? this.content.config.minWidth : null,
			maxWidth: this.content.config ? this.content.config.maxWidth : null,
			// position: this.content.config ? this.content.config.position : null
		});

		dialogRef.afterClosed().subscribe(result => {
			this.dialogResult = result;
			this.result.emit(this.dialogResult);
		});
	}
}

@Component({
	selector: "dialog-data",
	templateUrl: "dialog-data.component.html",
	styleUrls: ["./dialog.component.scss"],
})
export class DialogDataDialog {
	constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
