<form [formGroup]="form" class="mt-3" *ngIf="areaAtuacao != null">
    
    <mat-expansion-panel>
        <mat-expansion-panel-header class="panel-header">
            <mat-panel-title>
                <h3 class="form-title">Análise {{areaAtuacao.nome}} </h3>
            </mat-panel-title>
        </mat-expansion-panel-header>   
        <div class="row align-self-center">
            <div class="col-12">
                <mat-label style="font-weight: 600">Parecer</mat-label>
                <mat-form-field appearance="outline" class="col-12" style="margin-left: -16px">
                    <textarea class="textareaParecer" matInput rows="4" formControlName="parecer"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="!encerradoClicado" class="row align-self-center">
            <app-dropdown [form]="form" [items]="deferido" controlName="deferido" placeholder="Selecione se o documento vai ser Deferido ou Indeferido" (selectionChange)="deferimentoSelecionado($event)" class="col-3"></app-dropdown>
        </div>

        <app-ds-switch *ngIf="areaAtuacao.permitidoEncerrarSolic" 
        id="arquivada"
        inputId="switchArquivada"
        class="mb-5"
		label="Arquivar Solicitação?"
        (clique)="checkEncerrado($event)"
        ></app-ds-switch>

        <app-mensagem-tipo-padrao  *ngIf="encerradoClicado" [tipoMensagem]="tipoMensagemAlerta" [titulo]="titulo" [corpoMensagem]="corpoMensagem"></app-mensagem-tipo-padrao>

        <section>
            <div class="row align-self-center">
                <app-dropdown [form]="form" [items]="tiposDocumentos" controlName="tipoDocumento" placeholder="Selecione o Tipo do documento" (selectionChange)="tipoDocumentoSelecionado($event)" class="col-3"></app-dropdown>
                <app-input-text [form]="form" controlName="descricaoAnexo" placeholder="Descrição do Anexo" [showLabel]="false" class="col-3"></app-input-text>
            
                <app-ds-input-upload
                #campoDeArquivo
                [id]="tipoDocumentoEscolhido"
                [multiplosAquivos]="true"
                [tipoDocumentoId]="tipoDocumentoEscolhido"
                [urlApi]="urlApi" 
                [urlApiDelete]="urlApiDelete"
                (documentoSalvo)="documentoSalvo($event, tipoDocumentoEscolhido)"
                ></app-ds-input-upload>

                <div class="d-flex justify-content-end mb-2">
                    <button class="br-button secondary" type="button" (click)="adicionaDocumentos()">
                        <i class="material-icons">add</i> <span>Adicionar</span>
                    </button>
                    <button class="br-button primary align-self-end" type="button" data-toggle="modal" data-target="#gerarOficioModal" (click)="gerarOficio()">Gerar Oficio</button>
                </div>

                <app-gerar-oficio-modal></app-gerar-oficio-modal>
            </div>
        </section>
        <super-tabela 
            [header]="documentoAdicionadoHeader"
            [rows]="documentosUpload"
            (deletar)="deletarDocumento($event)"
            (download)="downloadArquivo($event)"
        ></super-tabela>
        <div *ngIf="campoComplementacoes">
            <h5 class="section-title mt-5">Complementação de Documentos</h5>
            <div class="row tabela">
                <super-tabela
                    [header]="complementacaoHeader"
                    [rows]="complementacaoRows"
                    (deletar)="deletarComplementacao($event)"
                    (editar)="editarComplementacao($event)"
                    (visualizar)="visualizarComplementacao($event)"
                    [paginaAtual]="paginaAtual"
                    [totalPaginas]="totalPaginas"
                    [totalItens]="totalItens"
                    [primeiraPagina]="primeiraPagina"
                    [ultimaPagina]="ultimaPagina"
                    (paginaSelecionada)="paginaSelecionada = $event; atualizarPaginas()"
                    class="col-12"
                ></super-tabela>
            </div>
        </div>

        <app-ds-switch
        id="docCompleta"
        inputId="documentacaoCompleta"
		label="Documentação completa?"
        (clique)="documentacaoCompleta($event)"
        ></app-ds-switch>

        <div *ngIf="switchDocumentacaoCompleta" class="d-flex justify-content-between">
            <button class="br-button primary align-self-end" (click)="onClickAbrirModalSalvarEncaminhar($event)">Salvar e Encaminhar Solicitação</button>
        </div>
        <div *ngIf="!switchDocumentacaoCompleta" class="d-flex justify-content-between">
            <button class="br-button primary align-self-end" (click)="solicitarComplementacao()">Salvar e Solicitar Complementação</button>
        </div>
    </mat-expansion-panel>
</form>