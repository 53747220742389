import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-ds-switch",
	templateUrl: "./ds-switch.component.html",
	styleUrls: ["./ds-switch.component.scss"],
})
export class DsSwitchComponent {

    @Input()
    label: string

    @Input()
    checked

    @Input()
    id: string;

    @Input()
    inputId: string;

    @Input()
    click: boolean = false;

    @Output() 
    clique: EventEmitter<any> = new EventEmitter<any>();

    onClickSwitch(clique: any) {
        this.click = !this.click;
        this.clique.emit(this.click);
    }

}